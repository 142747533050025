var App = {
    init: function () {
        this.scripts();
    },
    loaded: function () {},
    scripts: function () {
        // Is touch device?

        if ('ontouchstart' in window || window.navigator.MaxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0) {
            document.body.classList.add('touch');
        } else {
            document.body.classList.add('no-touch');
        }

        // Document has scrolled

        var did_scroll,
            last_scroll_top = 0,
            delta = 5;

        window.addEventListener('scroll', function () {
            did_scroll = true;

            if (window.scrollY >= 120) {
                document.body.classList.add('page-scrolled');
            } else {
                document.body.classList.remove('page-scrolled');
            }
        });

        function has_scrolled() {
            var scroll_top = window.scrollY,
                page_header = document.querySelector('.page-header');

            if (Math.abs(last_scroll_top - scroll_top) <= delta) return;

            if (scroll_top > last_scroll_top && scroll_top > 1) {
                page_header.classList.remove('nav-down');
                page_header.classList.add('nav-up');
            } else {
                if (scroll_top + window.innerHeight < App.get_document_height()) {
                    page_header.classList.remove('nav-up');
                    page_header.classList.add('nav-down');
                }
            }

            last_scroll_top = scroll_top;
        }

        setInterval(function () {
            if (did_scroll) {
                has_scrolled();
                did_scroll = false;
            }
        }, 250);

        // Menu hover test

        const menu_item = document.querySelectorAll('.main-nav .menu-item a');
        var mouse;
        var cursor = document.querySelector('.cursor-img');
        let mouseX = window.innerWidth / 2;
        let mouseY = window.innerHeight / 2;

        if (menu_item) {
            menu_item.forEach(function (item) {
                item.addEventListener('mousemove', function (event) {
                    mouseX = event.clientX;
                    mouseY = event.clientY;
                });
                item.addEventListener('mouseover', function () {
                    if (!cursor.dataset.bgset || (cursor.dataset.bgset == '0' && item.dataset.thumbnail)) {
                        cursor.dataset.bgset = '1';
                        cursor.style.backgroundImage = 'url(' + item.dataset.thumbnail + ')';
                    }
                    cursor.classList.add('visible');
                });
                item.addEventListener('mouseout', function () {
                    cursor.classList.remove('visible');
                    cursor.dataset.bgset = '0';
                    cursor.style.backgroundImage = '';
                });
            });
        }

        var circle = {
            el: document.querySelector('.cursor-img'),
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
            w: 500,
            h: 300,
            update: function () {
                l = this.x - window.innerWidth / 2;
                t = this.y - this.h / 2;
                this.el.style.transform = 'translate3d(' + l + 'px,' + t + 'px,0)';
                // console.log(this.x + ' | ' + window.innerWidth / 2);
            }
        };

        window.addEventListener('mousemove', function (e) {
            // mouseX = e.clientX;
            // mouseY = e.clientY;
        });

        setInterval(move, 1000 / 60);
        function move() {
            circle.x = lerp(circle.x, mouseX, 0.075);
            circle.y = lerp(circle.y, mouseY, 0.075);
            circle.update();
        }

        function lerp(start, end, amt) {
            return (1 - amt) * start + amt * end;
        }

        // Video menu
        const bgvid = document.querySelector('#bgvid');

        if (bgvid) {
            ['loadedmetadata', 'loaded', 'canplaythrough'].forEach(function (state) {
                bgvid.addEventListener(state, function () {
                    bgvid.play();
                });
            });
        }

        // Mobile menu

        const menu_wrapper = document.querySelector('.page-header');
        const menu_button = menu_wrapper.querySelector('.menu-btn');

        if (menu_wrapper && menu_button) {
            menu_button.addEventListener('click', function () {
                menu_wrapper.classList.toggle('opened');
                document.body.classList.toggle('opened');
            });

            window.addEventListener('keyup', function (event) {
                if (event.keyCode == 27 && document.body.classList.contains('opened')) {
                    menu_wrapper.classList.remove('opened');
                    document.body.classList.remove('opened');
                }
            });
        }

        // To top

        const back_to_top_button = document.querySelector('.back-top');

        if (back_to_top_button) {
            document.querySelector('.back-top').addEventListener('click', function () {
                window.scrollTo(0, 0);
            });
        }

        // Observer

        const observable_objects = document.querySelectorAll('.main-container .section .block');

        observable_objects.forEach(function (item) {
            item.classList.add('animate');
        });

        let threshold = 0.1;

        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (entry.target) {
                            entry.target.classList.add('in-viewport');
                            entry.target.classList.remove('animate');
                        }
                    } else {
                        // entry.target.classList.remove('in-viewport');
                    }
                });
            },
            {
                // rootMargin: '-' + parseInt(window.visualViewport.height / 3) + 'px'
                // root: document.body,
                // threshold: window.visualViewport.height > 768 ? 0.4 : 0.1
                threshold
            }
        );

        if (observable_objects) {
            observable_objects.forEach((el) => {
                observer.observe(el);
            });

            const pendingEntries = observer.takeRecords();

            // Process any pending entries
            pendingEntries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('in-viewport');
                } else {
                    // entry.target.classList.remove('in-viewport');
                }
            });
        }

        // Add a scroll event listener to dynamically adjust the threshold value
        window.addEventListener('scroll', () => {
            // Get the height of the visual viewport
            const viewportHeight = window.visualViewport.height;

            // Update the threshold value based on the viewport height
            threshold = viewportHeight > 768 ? 0.2 : 0.1;

            // Update the IntersectionObserver with the new threshold value
            observer.thresholds = [threshold];
        });

        // Page loading animations

        const main_menu = document.querySelectorAll('ul.menu a, .back-link a, .list-item a');

        main_menu.forEach((object) => {
            object.addEventListener('click', (event) => {
                event.preventDefault();

                const href = object.getAttribute('href');

                if (href.length) {
                    menu_wrapper.classList.remove('opened');
                    document.body.classList.remove('opened');

                    fetch(href).then(function (response) {
                        if (response.status == 200) {
                            document.body.classList.add('animate-out');

                            setTimeout(function () {
                                window.location = href;
                            }, 500);
                        }
                    });
                }
            });
        });

        // Guest Joy newsletter
        if (localStorage.getItem('cookie-consent') && localStorage.getItem('cookie-consent').split(',').includes('functional')) {
            const gj_newsletter = document.querySelector('#gj-newsletter');

            if (gj_newsletter) {
                gj_newsletter.classList.remove('d-none');
            }
        }
    },
    get_document_height: function () {
        return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight);
    }
};

document.addEventListener('DOMContentLoaded', function () {
    App.init();
});
window.addEventListener('pageshow', function (event) {
    if (event.persisted) {
        console.log('Loaded from cache');
        document.body.classList.remove('animate-out');
    }
});
window.addEventListener('load', function () {
    App.loaded();
});
